import React from "react";
// import Navigation from '../Navigation';

const PrivacyPolicy = () => {
  return (
    <>
      {/* <Navigation /> */}
      <div id="privacy-policy" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Privacy Policy</h2>
          </div>
          <div className="row">
            <p className="text-justify">
              Your privacy is important to us. It is our policy to respect your
              privacy regarding any information we may collect from you across
              our app, Trainmate, and other sites we own and operate. We only
              ask for personal information when we truly need it to provide a
              service to you. We collect it by fair and lawful means, with your
              knowledge and consent. We also let you know why we’re collecting
              it and how it will be used. We only retain collected information
              for as long as necessary to provide you with your requested
              service. What data we store, we’ll protect within commercially
              acceptable means to prevent loss and theft, as well as
              unauthorized access, disclosure, copying, use or modification. We
              don’t share any personally identifying information publicly or
              with third-parties. You are free to refuse our request for your
              personal information, with the understanding that we may be unable
              to provide you with some of your desired services. Your continued
              use of our app will be regarded as acceptance of our practices
              around privacy and personal information. If you have any questions
              about how we handle user data and personal information, feel free
              to contact us to{" "}
              <a href="mailto:contact@rastercell.com">contact@rastercell.com</a>{" "}
              . This policy is effective as of 1 March 2024.
            </p>
          </div>
        </div>
      </div>
      {/* <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; 2022{' '}
            <a href='/#page-top' rel='nofollow'>
              Rastercell
            </a>{' '}
            Web Solutions
          </p>
        </div>
      </div> */}
    </>
  );
};

export default PrivacyPolicy;
