import { useState, useEffect } from "react";
import Navigation from "./components/Navigation";
import Header from "./components/Header";
import Features from "./components/Features";
import About from "./components/About";
import Services from "./components/Services";
import Gallery from "./components/Gallery";
import Testimonials from "./components/Testimonials";
import Team from "./components/Team";
import Contact from "./components/Contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import ToastBody from "./common/ToastBody";
import "react-toastify/dist/ReactToastify.css";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <>
      <Navigation />
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      <Services data={landingPageData.Services} />
      <Gallery data={landingPageData.Gallery} />
      <Testimonials data={landingPageData.Testimonials} />
      <Team data={landingPageData.Team} />
      <PrivacyPolicy />
      <Contact data={landingPageData.Contact} />
      <ToastBody />
    </>
  );
};

export default App;
